<template>
    <div>
        <AccountingAndValuationPrinciples
            v-if="cardData.accountingAndValuationPrinciples != null"
            @saveData="saveData()"
            @close="closeCard"
            :toggle-value="cardData.accountingAndValuationPrinciples.addAccountingAndValuationPrinciple"
            @toggle-change="handleToggleChange"
            :title="cardData.accountingAndValuationPrinciples.headTitle"
            :input-text="cardData.accountingAndValuationPrinciples.text"
            @update:text="cardData.accountingAndValuationPrinciples.text = $event"
            :initial-input-text="cardData.accountingAndValuationPrinciples.text"
        />
        <GeneralClarifications
            v-if="cardData.generalClarifications != null"
            @saveData="saveData()"
            @close="closeCard"
            :toggle-value="cardData.generalClarifications.addGeneralClarification"
            @toggle-change="handleToggleChange"
            :title="cardData.generalClarifications.headTitle"
            :general-clarifications-data="cardData.generalClarifications"
            @update:clarifications="cardData.generalClarifications = $event"
        />
        <ServiceAndContractAssignments
            v-if="cardData.serviceAndContractAssignments != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.serviceAndContractAssignments.headTitle"
            :toggle-value="cardData.serviceAndContractAssignments.addServiceAndContractAssignment"
            @toggle-change="handleToggleChange"
            :service-and-contract-assignments-data="cardData.serviceAndContractAssignments"
            @update:data="cardData.serviceAndContractAssignments = $event"
        />
        <FixedAssets
            v-if="cardData.fixedAssets != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.fixedAssets.headTitle"
            :toggle-value="cardData.fixedAssets.addFixedAsset"
            @toggle-change="handleToggleChange"
            :fixed-assets-data="cardData.fixedAssets"
        />
        <KeyFigureDefinitions
            v-if="cardData.keyFigureDefinitions != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.keyFigureDefinitions.headTitle"
            :toggle-value="cardData.keyFigureDefinitions.addKeyFigureDefinition"
            @toggle-change="handleToggleChange"
            :input-text="cardData.keyFigureDefinitions.text"
            @update:text="cardData.keyFigureDefinitions.text = $event"
        />
        <div v-if="cardData.newCards != null">
            <NewCard
                @saveData="saveData()"
                @close="closeCard"
                @delete="removeCard(index)"
                :title="cardData.newCards.headTitle"
                :toggle-value="true"
                @toggle-change="handleToggleChange"
                :input-text="cardData.newCards"
                @update:text="cardData.newCards = $event"
                @move-up="moveUp(index)"
                @move-down="moveDown(index)"
                :input-disabled="inputDisabled"
            />
        </div>
        <div v-if="isLastIndex" class="flex flex-row pt-8">
            <el-form>
                <el-form-item class="pr-2">
                    <el-button size="medium" type="primary" @click="addCard()">Lägg till redovisningsprincip</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        AccountingAndValuationPrinciples: () => import("./AccountingAndValuationPrinciples.vue"),
        GeneralClarifications: () => import("./GeneralClarifications.vue"),
        ServiceAndContractAssignments: () => import("./ServiceAndContractAssignments.vue"),
        FixedAssets: () => import("./FixedAssets.vue"),
        KeyFigureDefinitions: () => import("./KeyFigureDefinitions.vue"),
        NewCard: () => import("./NewCard.vue"),
    },
    props: {
        annualReportId: {
            type: String,
            required: true,
        },
        cardData: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        lastIndex: {
            type: Number,
        },
        accountingPrinciplesData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            inputDisabled: true,
        };
    },
    methods: {
        async saveData() {
            this.$emit("saveData");
        },
        async closeCard(value) {
            this.$emit("close", value);
        },
        async handleToggleChange(eventData) {
            if (eventData.source === 1) {
                this.cardData.accountingAndValuationPrinciples.addAccountingAndValuationPrinciple = eventData.value;
            }
            if (eventData.source === 2) {
                this.cardData.generalClarifications.addGeneralClarification = eventData.value;
            }
            if (eventData.source === 3) {
                this.cardData.serviceAndContractAssignments.addServiceAndContractAssignment = eventData.value;
            }
            if (eventData.source === 4) {
                this.cardData.fixedAssets.addFixedAsset = eventData.value;
            }
            if (eventData.source === 5) {
                this.cardData.keyFigureDefinitions.addKeyFigureDefinition = eventData.value;
            }
            this.$emit("saveData");
        },
        addCard() {
            this.accountingPrinciplesData.cards.push({
                newCards: {
                    headTitle: "",
                    text: "",
                    addTable: false,
                    tableRows: [
                        {
                            columnOne: "",
                            columnTwo: "",
                        },
                        {
                            columnOne: "",
                            columnTwo: "",
                        },
                    ],
                },
            });
            this.$emit("saveData");
        },
        removeCard(index) {
            this.accountingPrinciplesData.cards.splice(index, 1);
        },
        moveUp(index) {
            this.$emit("move-up", { index: index });
        },
        moveDown(index) {
            this.$emit("move-down", { index: index });
        },
    },
    computed: {
        isLastIndex() {
            return this.index === this.lastIndex - 1;
        },
    },
};
</script>
